.App {
  text-align: left;
  padding: 5px 10px 0 10px;
  height: 100vh;
}
.list{
  margin-bottom: 1px;
  padding: 5px;
}
.critical, .major_outage{
  background-color: FireBrick;
}
.critical h1, .critical p, .critical a, .critical .icon, .critical .close, .major_outage a{
  color: white;
}
.major, .partial_outage{
  background-color: Orange;
}
.major h1, .major p, .major a, .major .icon, .major .close, .partial_outage a{
  color: white;
}
.minor, .degraded_performance{
  background-color: #fcc42b;
}
.minor h1, .minor p, .minor a, .minor .icon, .minor .close, .degraded_performance a{
  color: black;
}
.none, .maintenance, .under_maintenance{
  background-color: #009ee3;
}
.none h1, .none p, .none a, .none .icon, .none .close, .maintenance h1, .maintenance p, .maintenance a, .maintenance .icon, .maintenance .close, .under_maintenance a {
  color: white;
}
.unaffected, .operational{
  background-color: #3aa935;
}
.unaffected h1, .unaffected p, .unaffected a, .unaffected .icon, .unaffected .close, .operational a{
  color: white;
}
.icon, .info{
  display: table-cell;
  padding:0;
}
.icon {
  padding-right: 10px;
  vertical-align: top;
}
.title{
  max-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 260px;
}
.close{
  position: absolute;
  top: 2px;
  right: 5px
}
.body{
  max-height: 70px;
  overflow: hidden;
}
.bottom{
  position: absolute;
  bottom: 4px;
}
