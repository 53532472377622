body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
}
a {
  color: black;
}
h1, h2 {
  margin: 0;
  padding: 0;
  font-size: 1.0em;
}
p {
  margin: 0;
  padding: 0;
  font-size: 0.7em;
}
.lastupdated {
  font-size: 0.5em;
}
